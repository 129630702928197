export default {
  data() {
    return {
      isMobile: null,
    };
  },
  created() {
    const mobile = require("is-mobile");

    if (mobile({ tablet: true })) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
};
