import http from "@/utils/axios";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    referralGroups: [],
    pagination: {
      currentPage: 1,
      total: 1,
    },
    currentReferral: null,
    commissionRange: [1, 100],
    priceRange: [1, 1000],
  },
  getters: {
    getReferralGroups: (state) => state.referralGroups,
    getReferralById: (state) => (id) => {
      return state.referralGroups.find((referral) => referral.id === id);
    },
    getPagination: (state) => state.pagination,
    getCurrentReferral: (state) => state.currentReferral,
    getStats: (state) => state.stats,
    getCommissionRange: (state) => state.commissionRange,
    getPriceRange: (state) => state.priceRange,
  },
  mutations: {
    setReferralGroups(state, referralGroups) {
      state.referralGroups = referralGroups.data;
      state.pagination.currentPage = referralGroups.meta.current_page;
      state.pagination.total = referralGroups.meta.last_page;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
    setCurrentReferral(state, currentReferral) {
      state.currentReferral = currentReferral;
    },
    setCurrentReferralPatient(state, patient) {
      state.currentReferral.patient = patient;
    },
    setStats(state, stats) {
      state.stats = stats;
    },
    setCommissionRange(state, commissionRange) {
      state.commissionRange = commissionRange;
    },
    setPriceRange(state, priceRange) {
      state.priceRange = priceRange;
    },
    clearReferralGroups(state) {
      state.referralGroups = [];
      state.pagination.currentPage = 1;
      state.pagination.total = 1;
    },
    clearCurrentReferral(state) {
      state.currentReferral = null;
    },
  },
  actions: {
    async fetchReferralGroups({ commit, getters, rootGetters }) {
      const pagination = getters.getPagination;

      const { data } = await http.get("referralGroups", {
        params: {
          page: pagination.currentPage,
        },
      });

      commit("setReferralGroups", data);
      commit("user/setCurrentUserStats", data.stats, { root: true });
      let userRole = rootGetters["user/getCurrentUserRole"];
      if (userRole != "patient")
        commit("referrals/setCommissionRange", data.commission_range, {
          root: true,
        });
    },
    async checkResults(
      { commit, state },
      { id, time = "current_month", date_range = null, filters, sortBy }
    ) {
      const { data } = await http.get(`referrals/check-results/${id}`, {
        params: {
          page: state.pagination.currentPage,
          time: time,
          date_range: date_range,
          ...filters,
          ...sortBy,
        },
      });
      commit("setReferralGroups", data);
    },
    async fetchCurrentReferral({ commit }, id) {
      return await http
        .get(`/referrals/${id}`)
        .then(({ data }) => {
          commit("setCurrentReferral", data.referral);
        })
        .catch(({ response }) => {
          if (response && response.status === 403)
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
        });
    },
  },
};
