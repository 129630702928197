import http from "@/utils/axios";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    invoices: [],
    pagination: {
      currentPage: 1,
      total: 1,
    },
    stats: {
      new: 0,
      paid: 0,
      to_pay: 0,
    },
    totalPriceRange: [1, 10000],
  },
  getters: {
    getInvoices: (state) => state.invoices,
    getPagination: (state) => state.pagination,
    getStats: (state) => state.stats,
    getTotalPriceRange: (state) => state.totalPriceRange,
  },
  mutations: {
    setInvoices(state, invoices) {
      state.invoices = invoices.data;
      state.pagination.currentPage = invoices.meta.current_page;
      state.pagination.total = invoices.meta.last_page;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
    setStats(state, stats) {
      Object.assign(state.stats, stats);
    },
    setTotalPriceRange(state, totalPriceRange) {
      state.totalPriceRange = totalPriceRange;
    },
    clearInvoices(state) {
      state.invoices = [];
    },
  },
  actions: {
    async fetchInvoices({ commit, state }) {
      await http
        .get(`doctor/invoices`, {
          params: {
            page: state.pagination.currentPage,
          },
        })
        .then(({ data }) => {
          commit("setInvoices", data.invoices);
        })
        .catch(({ response }) => {
          if (response && response.status === 403)
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
        });
    },
    async fetchAdminInvoices(
      { commit, state },
      { time = "current_month", date_range = null, filters, sortBy }
    ) {
      await http
        .get(`admin/invoices`, {
          params: {
            page: state.pagination.currentPage,
            time: time,
            date_range: date_range,
            ...filters,
            ...sortBy,
          },
        })
        .then(({ data }) => {
          commit("setInvoices", data.invoices);
          commit("setStats", data.stats);
          commit("setTotalPriceRange", data.total_price_range);
        })
        .catch(({ response }) => {
          if (response && response.status === 403)
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
        });
    },
    async downloadInvoice(_, id) {
      return await http({
        url: `/invoices/download/${id}`,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const filename =
            response.headers["content-disposition"].split("filename=")[1];
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            URL.revokeObjectURL(url);
            link.remove();
          }, 1000);
        })
        .catch(({ response }) => {
          if (response && response.status === 403) {
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
            return;
          }
          notify({
            type: "danger",
            text: "messages.download.failure",
            duration: 5000,
          });
        });
    },
    async downloadCSV(_, id) {
      return await http({
        url: `/patient-csv/${id}/export`,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const filename =
            response.headers["content-disposition"].split("filename=")[1];
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            URL.revokeObjectURL(url);
            link.remove();
          }, 1000);
        })
        .catch(({ response }) => {
          if (response && response.status === 403) {
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
            return;
          }
          notify({
            type: "danger",
            text: "messages.download.failure",
            duration: 5000,
          });
        });
    },
    async downloadInvoiceData(_, id) {
      return await http({
        url: `/download/${id}`,
        method: "GET",
      }).catch(({ response }) => {
        if (response && response.status === 403) {
          notify({
            type: "danger",
            text: "messages.permission.failure",
            duration: 5000,
          });
          return;
        }
        notify({
          type: "danger",
          text: "messages.download.failure",
          duration: 5000,
        });
      });
    },
    async downloadZip(_, date) {
      return await http({
        url: `/admin/invoices/generate-download-url/${date}`,
        method: "GET",
      })
        .then(({ data }) => {
          window.open(data, "_blank");
        })
        .catch(({ response }) => {
          if (response && response.status === 403)
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
          if (response && response.status === 404)
            notify({
              type: "info",
              text: "messages.download.no_invoice",
              duration: 5000,
            });
        });
    },
  },
};
