import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import { vue3Debounce } from "vue-debounce";
import Notifications from "@kyvg/vue3-notification";
import App from "./App.vue";
import VOtpInput from "vue3-otp-input";
import router from "./router";
import store from "./store";
import acceptLanguage from "accept-language";
import en from "./languages/en";
import pl from "./languages/pl";
import ua from "./languages/ua";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import { setupCalendar, Calendar, DatePicker } from "v-calendar";
import { MonthPicker, MonthPickerInput } from "vue-month-picker";
import vClickOutside from "click-outside-vue3";
import breadcrumbsMixin from "@/mixins/breadcrumbsMixin";
import DatepickerInput from "@vuepic/vue-datepicker";

import "@/assets/styles/index.scss";
import "@/assets/styles/transitions.scss";
import "@/assets/styles/slider.scss";
import "v-calendar/dist/style.css";
import "vue-loading-overlay/dist/vue-loading.css";
import "maz-ui/css/main.css";
import "@/assets/styles/number-phone-input.scss";
import "@vuepic/vue-datepicker/dist/main.css";
import "@/assets/styles/date-picker.scss";

// Initialize the I18n support
acceptLanguage.languages(["en", "pl", "ua"]);

const i18n = createI18n({
  locale:
    acceptLanguage.get(
      localStorage.getItem("language") || navigator.language.substring(0, 2)
    ) || "en",
  fallbackLocale: "pl",
  messages: {
    en,
    pl,
    ua,
  },
});

const app = createApp(App);

app.config.globalProperties.currency = "грн";

app.use(i18n);
app.use(store);
app.use(router);
app.use(Notifications);
app.use(setupCalendar, {});
app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  },
});
app.use(MonthPicker);
app.use(MonthPickerInput);
app.use(vClickOutside);

app.directive("debounce", vue3Debounce({ lock: true }));

app.mixin(breadcrumbsMixin);

app.component("Calendar", Calendar);
app.component("DatePicker", DatePicker);
app.component("DatepickerInput", DatepickerInput);
app.component("v-otp-input", VOtpInput);

app.mount("#app");
