import http from "@/utils/axios";

export default {
  namespaced: true,
  state: {
    categories: null,
  },
  getters: {
    getAllCategories: (state) => state.categories,
    getCategoryById: (state) => (id) =>
      state.categories.find((category) => category.id === id),
  },
  mutations: {
    setAllCategories(state, categories) {
      state.categories = categories;
    },
  },
  actions: {
    async fetchMedicalCategories({ commit }) {
      const response = await http.get("/main-medical-categories");
      const categories = response.data.medicalCategories;
      commit("setAllCategories", categories);
    },
  },
};
