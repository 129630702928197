export default {
  namespaced: true,
  state: {
    userLoader: true,
    contentLoader: false,
    resultsLoader: false,
  },
  getters: {
    getUserLoaderStatus: (state) => state.userLoader,
    getContentLoaderStatus: (state) => state.contentLoader,
    getResultsLoader: (state) => state.resultsLoader,
  },
  mutations: {
    userIsLoading(state) {
      state.userLoader = true;
    },
    userLoaded(state) {
      state.userLoader = false;
    },
    contentIsLoading(state) {
      state.contentLoader = true;
    },
    contentLoaded(state) {
      state.contentLoader = false;
    },
    resultsIsLoading(state) {
      state.resultsLoader = true;
    },
    resultsLoaded(state) {
      state.resultsLoader = false;
    },
  },
};
