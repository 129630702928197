import http from "@/utils/axios";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    patients: [],
    patientsCount: null,
    errors: [],
    pagination: {
      currentPage: 1,
      total: 1,
    },
    statuses: [
      {
        id: 1,
        name: "active",
      },
      {
        id: 3,
        name: "blocked",
      },
    ],
    search: "",
    currentPatient: {
      first_name: "",
      last_name: "",
      phone_number: "",
      identity_card: "",
      pesel: "",
      birth_date: "",
      day: "",
      month: "",
      year: "",
      gender: "",
    },
    currentPatientStats: {
      referrals: {
        issued: 0,
        paid: 0,
        canceled: 0,
        completed: 0,
      },
      total_price: 0,
    },
  },
  getters: {
    getPatients: (state) => state.patients,
    getPatientsCount: (state) => state.patientsCount,
    getErrors: (state) => state.errors,
    getPagination: (state) => state.pagination,
    getSearch: (state) => state.search,
    getCurrentPatient: (state) => state.currentPatient,
    getCurrentPatientStats: (state) => state.currentPatientStats,
    getStatuses: (state) => state.statuses,
    getStatusById: (state) => (id) => {
      return state.statuses.find((status) => status.id === id);
    },
    getPatientById: (state) => (id) => {
      return state.patients.find((patient) => patient.id === id);
    },
  },
  mutations: {
    setPatients(state, patients) {
      state.patients = patients.data;
      state.pagination.currentPage = patients.meta.current_page;
      state.pagination.total = patients.meta.last_page;
    },
    setPatientsCount(state, patientsCount) {
      state.patientsCount = patientsCount;
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    addError(state, { key, error }) {
      state.errors = {
        ...state.errors,
        [key]: error,
      };
    },
    clearErrors(state) {
      state.errors = [];
    },
    setSearch(state, search) {
      state.search = search;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
    setCurrentPatient(state, currentPatient) {
      state.currentPatient = currentPatient;
    },
    setCurrentPatientStats(state, stats) {
      state.currentPatientStats = stats;
    },
    setCurrentPatientValue(state, currentPatient) {
      Object.assign(state.currentPatient, currentPatient);
    },
    setBirthDate(state, currentPatient) {
      Object.assign(state.currentPatient, currentPatient);
      const { year, day, month } = state.currentPatient;
      state.currentPatient.birth_date = `${day}.${month}.${year}`;
    },
    clearCurrentPatient(state) {
      Object.keys(state.currentPatient).forEach(
        (key) => (state.currentPatient[key] = "")
      );
    },
    updatePatient(state, user) {
      const index = state.patients.findIndex(
        (patient) => patient.id === user.id
      );

      if (index >= 0) {
        state.patients[index] = user;
      }
    },
    clearCurrentPatientStats(state) {
      Object.keys(state.currentPatientStats.referrals).forEach(
        (key) => (state.currentPatientStats.referrals[key] = 0)
      );
      state.currentPatientStats.total_price = 0;
    },
    clearSearch(state) {
      state.search = "";
    },
    clearPatients(state) {
      state.patients = [];
      state.pagination.currentPage = 1;
      state.pagination.total = 1;
    },
  },
  actions: {
    async fetchPatients({ commit, state }, { filters, sortBy }) {
      const { data } = await http.get("admin/patients", {
        params: {
          page: state.pagination.currentPage,
          search: state.search,
          ...filters,
          ...sortBy,
        },
      });
      const patients = data.patients;
      commit("setPatients", patients);
      commit("setPatientsCount", data.patients_count);
    },
    async savePatient({ commit }, patient) {
      return await http
        .post(`/doctor/patients/store`, patient)
        .then(({ data }) => {
          commit("clearErrors");
          commit("clearCurrentPatient");
          return data.user;
        })
        .catch(({ response }) => {
          if (response && response.status === 422) {
            commit("setErrors", response.data.errors);
            return false;
          }
          if (response && response.status === 403) {
            return null;
          }
        });
    },
    async changeStatus(_, data) {
      return await http
        .post(`/admin/patients/change-status/${data.id}`, {
          status: data.status,
        })
        .then(() => {
          notify({
            type: "success",
            text: "messages.save.success",
            duration: 5000,
          });
        })
        .catch(({ response }) => {
          if (response && response.status === 422)
            notify({
              type: "danger",
              text: "messages.save.failure",
              duration: 5000,
            });
        });
    },
    async fetchCurrentPatient(
      { commit, rootState },
      { id, time = "current_month", date_range = null, filters, sortBy }
    ) {
      return await http
        .get(`doctor/patients/${id}`, {
          params: {
            page: rootState.referrals.pagination.currentPage,
            time: time,
            date_range: date_range,
            ...filters,
            ...sortBy,
          },
        })
        .then(({ data }) => {
          commit("setCurrentPatient", {
            ...data.patient,
            phone_is_valid: true,
          });
          commit("setCurrentPatientStats", data.stats);
          commit("referrals/setReferrals", data.referrals, { root: true });
          commit("referrals/setPriceRange", data.price_range, {
            root: true,
          });
        })
        .catch(({ response }) => {
          if (response && response.status === 403)
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
        });
    },
  },
};
