import http from "@/utils/axios.js";
export default {
  namespaced: true,
  state: {
    currentLanguage: null,
  },
  getters: {
    getCurrentLanguage: (state) => state.currentLanguage,
  },
  mutations: {
    setCurrentLanguage(state, language) {
      state.currentLanguage = language;
    },
  },
  actions: {
    initCurrentLanguage({ commit, rootGetters }) {
      let language =
        localStorage.getItem("language") || navigator.language.substring(0, 2);

      if (
        !rootGetters["languages/getAllLanguages"]
          .map((lang) => lang.code)
          .includes(language)
      ) {
        language = "en";
      }

      commit(
        "setCurrentLanguage",
        rootGetters["languages/getLanguageByCode"](language)
      );
    },

    setCurrentLanguageByCode({ commit, rootGetters }, code) {
      localStorage.setItem("language", code);
      http.defaults.headers["Accept-Language"] = code;
      commit(
        "setCurrentLanguage",
        rootGetters["languages/getLanguageByCode"](code)
      );
    },
  },
};
