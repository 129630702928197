import { mapMutations } from "vuex";
import _ from "lodash";

export default {
  methods: {
    updateBreadCrumbs() {
      const params = this.$options.breadcrumbs.map(({ key, valueKey }) => {
        return { key, value: _.get(this, valueKey) };
      });

      this.updateCrumbsParams(params);
    },
    ...mapMutations({
      updateCrumbsParams: "breadcrumbs/updateCrumbsParams",
    }),
  },
};
