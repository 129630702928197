<template>
  <router-view
    v-slot="{ Component }"
    v-if="!isLoadingUser && !isLoadingFeatures"
  >
    <transition name="fade" mode="out-in" appear>
      <component :is="Component" />
    </transition>
  </router-view>
  <Notification />
  <Loading
    :active="isLoadingUser || isLoadingFeatures"
    :can-cancel="false"
    :is-full-page="true"
    color="#0C1E67"
    loader="dots"
    :opacity="0"
  />

  <Loading
    v-model:active="isLoadingContent"
    :can-cancel="false"
    :is-full-page="true"
    color="#0C1E67"
    loader="dots"
    :opacity="0.7"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Notification from "@/layouts/Notification";
import Loading from "vue-loading-overlay";

export default {
  name: "App",
  components: {
    Notification,
    Loading,
  },

  computed: {
    ...mapGetters({
      isLoadingFeatures: "features/isLoadingFeatures",
      isLoadingUser: "loader/getUserLoaderStatus",
      isLoadingContent: "loader/getContentLoaderStatus",
    }),
  },
  methods: {
    ...mapActions({
      initCurrentLanguage: "language/initCurrentLanguage",
      fetchFeatures: "features/fetchFeatures",
    }),
  },
  created() {
    this.initCurrentLanguage();
    this.fetchFeatures();
  },
};
</script>
