import http from "@/utils/axios";

export default {
  namespaced: true,
  state: {
    enabled: [],
    isLoading: false,
  },
  getters: {
    isEnabled: (state) => (name) => state.enabled.includes(name),
    isLoadingFeatures: (state) => state.isLoading,
  },
  mutations: {
    setFeatures(state, payload) {
      state.enabled = payload;
    },
    setLoading(state, payload) {
      state.isLoading = payload;
    },
  },
  actions: {
    async fetchFeatures({ commit }) {
      commit("setLoading", true);
      const { data } = await http.get("features/enabled");
      commit("setFeatures", data);
      commit("setLoading", false);
    },
  },
};
