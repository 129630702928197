export default {
  namespaced: true,
  state: {
    modals: [
      { name: "createPatient", isOpen: false },
      { name: "changeBillingData", isOpen: false },
      { name: "changePassword", isOpen: false },
      { name: "updateUserData", isOpen: false },
      { name: "emptyDoctorsList", isOpen: false },
      { name: "messageRecipientsCount", isOpen: false },
      { name: "referralEditWarning", isOpen: false },
      { name: "filters", isOpen: false },
      { name: "createOwnPackage", isOpen: false },
      { name: "createNewOwnPackage", isOpen: false },
      { name: "deleteNewOwnPackage", isOpen: false },
      { name: "updateNewOwnPackage", isOpen: false },
      { name: "doctorNotActive", isOpen: false },
      { name: "confirmInvoiceIssued", isOpen: false },
      { name: "addInvoiceFile", isOpen: false },
    ],
  },
  getters: {
    isOpenModal: (state) => (name) => {
      return state.modals.find((modal) => modal.name === name).isOpen;
    },
    getModalData: (state) => (name) => {
      return state.modals.find((modal) => modal.name === name);
    },
  },
  mutations: {
    closeModal(state, name) {
      const currentModal = state.modals.find((modal) => modal.name === name);
      currentModal.isOpen = false;
    },
    openModal(state, name) {
      const currentModal = state.modals.find((modal) => modal.name === name);
      currentModal.isOpen = true;
    },
    openModalCallback(state, payload) {
      const defaultCallbacks = { onConfirm: () => {}, onCancel: () => {} };
      const index = state.modals.findIndex(
        (modal) => modal.name === payload.name
      );

      state.modals[index] = {
        ...state.modals[index],
        ...defaultCallbacks,
        ...payload,
        isOpen: true,
      };
    },
  },
};
