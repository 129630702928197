import http from "@/utils/axios";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    errors: [],
    raportLinks: null,
    pagination: {
      currentPage: 1,
      total: 1,
    },
  },
  getters: {
    getErrors: (state) => state.errors,
    getRaportLinks: (state) => state.raportLinks,
    getPagination: (state) => state.pagination,
  },
  mutations: {
    setErrors(state, errors) {
      state.errors = errors;
    },
    clearErrors(state) {
      state.errors = [];
    },
    setRaportLinks(state, raportLinks) {
      state.raportLinks = raportLinks.data.links.data;
      state.pagination.currentPage = raportLinks.data.links.current_page;
      state.pagination.total = raportLinks.data.links.last_page;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
    setCurrentPackagePage(state, currentPage) {
      state.packagePagination.currentPage = currentPage;
    },
  },
  actions: {
    async downloadRaport({ commit }, url) {
      return await http({
        url: url,
        method: "GET",
      })
        .then((response) => {
          commit("clearErrors");
          const filename =
            response.headers["content-disposition"].split("filename=")[1];
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            URL.revokeObjectURL(url);
            link.remove();
          }, 1000);
        })
        .catch(({ response }) => {
          if (response && response.status === 422) {
            commit("setErrors", response.data.errors);
            notify({
              type: "danger",
              text: "messages.download.failure",
              duration: 5000,
            });
          } else if (response && response.status === 403) {
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
            return;
          } else {
            notify({
              type: "danger",
              text: "messages.download.failure",
              duration: 5000,
            });
          }
        });
    },
    async fetchRaportLinks({ commit, state }) {
      const response = await http.get("admin/raports/daily-raport-links", {
        params: {
          page: state.pagination.currentPage,
        },
      });
      const links = response;
      commit("setRaportLinks", links);
    },
  },
};
