export default {
  account: {
    setting: "Ustawienia konta",
    default_price_list: "Domyślny cennik badań laboratoryjnych",
    pwz: "pwz",
    personal_data: "Dane osobowe",
    email: "Adres e-mail",
    password: "Hasło",
    update_data: "Edytuj swoje dane",
    change_password: "Zmień swoje hasło",
    blocked: "Zostałeś zablokowany",
    contact_administrator: "Skontaktuj się z administratorem",
  },

  action: {
    change: "Zmień",
    see_details: "Zobacz szczegóły",
    see_doctor: "Zobacz lekarza",
    see_referral: "Zobacz zlecenie",
    go_back: "Wróć",
    save_changes: "Zapisz zmiany",
    choose: "Wybierz",
    continue: "Kontynuuj",
    save: "Zapisz",
    clear: "Wyczyść",
    return: "Powrót",
    filter: "Filtruj",
    withdraw_funds: "Wypłać środki",
    edit: "Edytuj",
    download: "Pobierz",
    expand: "Rozwiń",
    collapse: "Zwiń",
    add: "Dodaj",
    search: "Szukaj",
    cancel: "Anuluj",
    show_more: "Zobacz więcej",
  },

  admin: {
    cities: {
      all: "Wszystkie",
    },
    message: {
      title: "Tytuł",
      message: "Treść wiadomości",
      send: "Wyślij wiadomość",
      choose_doctors: "Zaznacz do kogo chcesz wysłać wiadomość",
      sent: "Wiadomość została wysłana do",
      recipients: "odbiorcy/ów",
      recipients_number: "Liczba odbiorców",
    },

    doctor: {
      create: "Dodaj lekarza",
      doctor_data: "Dane lekarza",
      company_data: "Dane firmy",
      address: "Adres",
      account_number: "Numer rachunku bankowego",
      first_name: "Imię",
      last_name: "Nazwisko",
      pwz: "PWZ",
      main_medical_category: "Specjalizacja",
      email: "E-mail",
      registered_at: "Data rejestracji",
      phone_number: "Telefon",
      company_name: "Nazwa firmy / przychodni",
      nip: "NIP",
      street: "Ulica",
      house_number: "Numer budynku",
      flat_number: "Numer lokalu",
      postal_code: "Kod pocztowy",
      city: "Miasto",
      edit: "Edytuj lekarza",
      data_edit: "Edycja danych",
      referrrals_list: "Lista zleceń",
      send_message: "Wyślij wiadomość",
      status: "Status",
      full_name: "Imię i nazwisko",
      commission: "Wynagrodzenie",
      company: "Firma",
      back_to_list: "Wróć do listy lekarzy",
      search_doctor: "Znajdź lekarza",
      search_patient: "Znajdź pacjenta",
      account_inactice: "Konto jest nieaktywne",
      wait_for_activation:
        "Poczekaj do 24h na walidację konta ze strony naszych pracowników",
      contact_info: "Skontaktuj się z nami przez chat w godzinach 6:00 - 23:30",
      we_call_to_you: "Zadzwonimy do Ciebie",
      check_account_settings:
        "Sprawdz ustawienia swojego konta, dodaj dane do faktury jeżeli potrzebujesz",
      go_to_settings: "Przejdź do Ustawień",
    },

    invoices: {
      invoices: "Faktury",
      invoice_number: "Numer faktury",
      amount: "Kwota",
      doctor: "Lekarz",
      account_number: "Numer konta",
      date_of_issue: "Data wystawienia",
      date_of_transfer: "Data przelewu",
      download_all: "Pobierz wszystkie",
      download_invoice: "Pobierz fakturę",
      new: "Nowe",
      paid: "Opłacone",
      amount_to_pay: "Kwota do zapłaty",
      select_month: "Wybierz miesiąc z którego chcesz pobrać faktury",
      statuses: {
        new: "Nowa",
        paid: "Opłacona",
        to_send: "Do wysłania",
        all: "Wszystkie",
        sent: "Wysłane",
      },
      purchaser_name: "Zamawiający",
      order_number: "Numer zamówienia",
      date_of_order: "Data złożenia zamówienia",
      date_execution: "Data realizacji",
      invoice_issued: "Wystawiona",
      mark_as_issued: "Oznacz jako wystawioną",
      mark_as_issued_confirm:
        "Potwierdź, ze chcesz zmienic status faktury na wystawioną",
      csv: "csv",
      status: "status",
      select_file: "Wybierz plik",
      add_file: "Dodaj plik",
    },

    doctor_card: {
      status: "Status",
      referral_number: "Numer zlecenia",
      total_price: "Cena",
      commission: "Wynagrodzenie",
      patient: "Pacjent",
      date_of_issue: "Data wystawienia",
      date_of_examination: "Data wykonania badania",
      city: "Miasto",
      street: "Ulica",
      postal_code: "Kod pocztowy",
    },
  },

  auth: {
    create_account: "Załóż konto",
    enter_code: "Wpisz kod, który otrzymałeś w wiadomości SMS",
    enter_code_below: " Wpisz ten kod poniżej",
    enter_new_password: "Podaj nowe hasło do twojego konta",
    email: "Adres e-mail",
    first_name: "Imię",
    go_back_to_login: "Wróć do logowania",
    have_account: "Masz już konto?",
    last_name: "Nazwisko",
    full_name: "Imię i nazwisko",
    logout: "Wyloguj",
    not_have_account: "Nie masz jeszcze konta?",
    not_receive_code: "Kod do mnie nie dotarł",
    not_remember_password: "Nie pamiętasz hasła?",
    password: "Hasło",
    password_confirm: "Powtórz hasło",
    password_remind: "Przypomnij hasło",
    password_reset: "Resetuj hasło",
    phone: "Nr telefonu",
    pwz: "Nr PWZ",
    register: "Zarejestruj się",
    reset: "Reset hasła",
    send_reset_link: "Na podany adres e-mail wyślemy link resetujący hasło",
    sent_reset_link: "Wysłaliśmy Ci SMS z kodem weryfikacyjnym pod numer",
    sent_reset_link_email:
      "Wysłaliśmy Ci wiadomość e-mail z linkiem do resetu hasła.",
    sent_reset_link_error:
      "Link został wysłany, odczekaj chwilę aby otrzymać nowy link.",
    reset_link_expired: "Twój link do resetu hasła wygasł",
    sign_in: "Zaloguj się",
    sign_up: "Zarejestruj się",
    sms: "Kod SMS",
    specialist_panel: "do panelu specjalisty",
    patient_panel: "do panelu pacjenta",
    verify: "Weryfikuj",
    pay_for_examinations: "aby opłacić badania",
    enter_email_and_password: "Wpisz swój email i hasło,",
    to_create_account: "aby utworzyć konto w portalu",
    enter_phone_number: "Wpisz swój numer telefonu",
    enter_pesel: "Wpisz swój pesel",
    login_by_pesel: "Zarejestruj się używając pesel",
    login_by_phone: "Zarejestruj się używając numeru telefonu",
    identity_card: "Nr dowodu",
    pesel: "Pesel",
    current_password: "Obecne hasło",
    set_password: "Ustaw hasło",
    salary: "Wynagrodzenie",
    patient_account: "do konta Pacjenta",
    country_code: "Numer kierunkowy",
    phone_number: "Numer telefonu",
    checkboxes: {
      information_obligation: "Obowiązku informacyjnym",
      agree: "Oświadczam, że zapoznałem/zapoznałam się i akceptuję",
      agree_marketing:
        "Wyrażam zgodę na przetwarzanie moich danych osobowych, tj. adresu e-mail, w celu przesyłania newslettera drogą elektroniczną",
      agree_marketing_expand:
        " przez ALAB laboratoria Sp. z o.o. ul. Stępińska 22/30, 00-739 Warszawa, zgodnie z art. 10 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (t.j. Dz. U. z 2017 r. poz. 1219). Oświadczam, iż zostałam/em poinformowany o moich uprawnieniach, o których szerzej mogę przeczytać w ",
      agree_contact:
        "Wyrażam zgodę na kontaktowanie się ze mną przez ALAB laboratoria Sp. z o.o. z siedzibą w Warszawie",
      agree_contact_expand:
        " przy ul. Stępińskiej 22/30, 00-739 Warszawa, w celu omówienia warunków umowy za pomocą telekomunikacyjnych urządzeń końcowych i automatycznych systemów wywołujących zgodnie z ustawą z dnia 16 lipca 2004 r. – Prawo telekomunikacyjne czyli m.in. za pomocą telefonu komórkowego",
      terms_of_service: "Regulamin serwisu",
      service: "serwisu",
      and: "oraz",
      privacy_policy: "Politykę prywatności",
      agree_processing_my_personal_data:
        "Wyrażam zgodę na przetwarzanie moich danych osobowych wskazanych w powyższym formularzu w celu założenia konta.",
      agree_processing_my_personal_data_expand:
        " Administratorem danych jest ALAB laboratoria Sp. z o.o. ul. Stępińska 22/30, 00-739 Warszawa. Oświadczam, że zostałam/em poinformowany o moich uprawnieniach, o których szerzej mogę przeczytać w",
      terms: "Regulamin",
    },
    commission_options: {
      d15p0: "15% dla lekarza, 0% dla pacjenta",
      d10p5: "10% dla lekarza, 5% dla pacjenta",
      d5p10: "5% dla lekarza, 10% dla pacjenta",
      d0p15: "0% dla lekarza, 15% dla pacjenta",
      other: "Inne",
    },
    birth_date: "Data urodzenia",
    day: "Dzień",
    month: "Miesiąc",
    year: "Rok",
    no_pesel: " Brak numeru PESEL",
    enter_pesel_number: "Podaj numer PESEL",
    enter_birth_date: "Podaj datę urodzenia",
    gender: {
      title: "Płeć",
      m: "Mężczyzna",
      f: "Kobieta",
    },
  },

  cart: {
    selected_examinations: "Wybrane badania",
    no_examinations: "Nie wybrałeś jeszcze żadnych badań do zlecenia.",
    nurse_service: "Usługa pielęgniarska",
    covid_service: "Usługa pielęgniarska COVID",
    total: "Łącznie",
    your_commission: "Twoje wynagrodzenie",
    your_discount: "Zniżka",
    issue_referral: "Wystaw zlecenie",
    edit_referral: "Edytuj zlecenie",
    edit_referral_warning:
      "Edycja tego zlecenia spowoduje wystawienie nowego zlecenia i anulowanie obecnego zlecenia.",
    pay_referral: "Zapłać",
    details: "Szczegóły",
    selected_collection_point: "Wybrany Punkt Pobrań",
  },

  collection_facility: {
    collection_facility_title: "Wybierz Punkt Pobrań",
    collection_facility_text:
      "Ustaw dla którego punktu chcesz mieć pokazywany cennik badań laboratoryjnych. będziesz mógł go zmienić w każdej chwili w swoim profilu.",
    search_placeholder: "Wpisz miasto lub ulicę",
    current_location_error:
      "Nie pozwolileś przeglądarce na pobranie lokalizacji. Spróbuj ponownie.",
    hide_list: "Ukryj listę",
    show_list: "Pokaż listę",
    show_filters: "Pokaż filtry",
    hide_filters: "Ukryj filtry",
    show_on_map: "Pokaż na mapie",
    set_the_route: "Wyznacz trasę",
  },

  dashboard: {
    inaccessible_facility:
      "Twój Punkt Pobrań jest nieaktywny bądź nie można w nim wykonać badań.",
    inaccessible_facility_btn: "Zmień Punkt Pobrań",
    commission_info:
      "Wynagrodzenie jest sumą kwot należnych do wypłaty za zlecenia zrealizowane",
  },

  doctor_status: {
    blocked: "Zablokowany",
    active: "Aktywny",
    inactive: "Nieaktywny",
    all: "Wszystkie",
  },

  referral_type: {
    home: "Alab w domu",
    doctor: "AlabOnline",
    sport: "AlabSport",
    withoutSport: "bez AlabSportu",
    all: "Wszystkie",
  },

  empty_list: {
    referrals: "Lista zleceń jest pusta.",
    doctors: "Lista lekarzy jest pusta.",
    patients: "Lista pacjentów jest pusta.",
    invoices: "Lista faktur jest pusta.",
  },

  error: {
    accept_data_processing: {
      accepted: "Zgoda jest wymagana.",
    },
    agree_contact: {
      accepted: "Zgoda jest wymagana.",
    },
    gender: {
      required: "Płeć jest wymagana.",
    },
    accept_terms: {
      accepted: "Zgoda jest wymagana.",
    },
    accept_marketing: {
      accepted: "Zgoda jest wymagana.",
    },
    code: {
      required: "Kod sms jest wymagany.",
      incorrect: "Podany kod jest nieprawidłowy.",
    },
    email: {
      already_taken: "Podany email został już wykorzystany.",
      credentials_not_match: "Dane uwierzytelniające są nieprawidłowe.",
      email: "Email musi być poprawnym adresem email.",
      required: "Email jest wymagany.",
      selected_invalid: "Podany email nie istnieje w bazie.",
    },
    pesel: {
      required: "Pesel jest wymagany.",
      already_taken: "Podany pesel został już wykorzystany.",
      digits: "Pesel musi mieć długość 11.",
      invalid: "Niepoprawny numer pesel.",
      required_if_empty: {
        birth_date: "Pesel lub data urodzenia jest wymagana.",
      },
      required_when_not_present: {
        phone_number: "Numer telefonu lub pesel jest wymagany.",
      },
      incorrect: "Nie możemy odnaleźć użytkownika o podanym peselu.",
    },
    birth_date: {
      required: "Data urodzenia jest wymagana.",
      required_if_empty: {
        pesel: "Pesel lub data urodzenia jest wymagana.",
      },
      must_be_date_after_or_equal:
        "Data urodzenia musi być większa lub równa dacie 01.01.1900.",
      must_be_a_date_before: "Nie można wybrać takiej daty urodzenia.",
      invalid_date: "Data urodzenia nie jest poprawną datą.",
      incorrect: "Data urodzenia oraz pesel różnia się od siebie",
    },
    first_name: {
      required: "Imię jest wymagane.",
    },
    last_name: {
      required: "Nazwisko jest wymagane.",
    },
    main_medical_category_id: {
      required: "Proszę wybrać kategorie.",
    },
    current_password: {
      required: "Obecne hasło jest wymagane.",
      at_least_characters: "Obecne hasło musi mieć co najmniej 8 znaków.",
      invalid_format:
        "Obecne hasło musi zawierać przynajmniej jedną małą literę, jedną dużą literę i jedną cyfrę.",
      incorrect: "Obecne hasło jest nieprawidłowe.",
    },
    password: {
      required: "Hasło jest wymagane.",
      at_least_characters: "Hasło musi mieć co najmniej 8 znaków.",
      invalid_format:
        "Hasło musi zawierać przynajmniej jedną małą literę, jedną dużą literę i jedną cyfrę.",
    },
    password_confirm: {
      required: "Potwierdzenie hasła jest wymagane.",
      must_match: "Hasło i potwierdzenie hasła muszą być takie same.",
    },
    phone_number: {
      already_taken: "Podany numer telefonu został już wykorzystany.",
      required: "Nr telefonu jest wymagany.",
      digits: "Nr telefonu musi mieć długość 9.",
      incorrect: "Nie możemy odnaleźć użytkownika o podanym nr telefonu.",
      choose_country: "Wybierz kraj",
      required_when_not_present: {
        pesel: "Numer telefonu lub pesel jest wymagany.",
      },
      accepted: "Błędny numer telefonu",
    },
    national_number: {
      required: "Nr telefonu jest wymagany",
    },
    phone_is_valid: {
      accepted: "Niepoprawny numeru telefonu.",
    },
    pwz: {
      required: "Pwz jest wymagane.",
    },
    company_name: {
      required: "Nazwa firmy / przychodni jest wymagana.",
    },
    nip: {
      required: "Nip jest wymagany.",
      digits: "Nip musi mieć długość 10.",
    },
    street: {
      required: "Ulica jest wymagana.",
    },
    house_number: {
      required: "Numer budynku jest wymagana.",
    },
    postal_code: {
      required: "Kod pocztowy jest wymagany.",
    },
    city: {
      required: "Miejscowość jest wymagana.",
    },
    account_number: {
      required: "Numer rachunku bankowego jest wymagany.",
      invalid: "Numer rachunku bankowego jest niepoprawny.",
      invalid_lenght: "Numer rachunku bankowego powinien posiadać 30 znaków.",
      invalid_checksum: "Numer rachunku bankowego jest niepoprawny",
    },
    agree1: {
      accepted: "Zgoda jest wymagana.",
    },
    identity_card: {
      required: "Nr dowodu jest wymagany.",
    },
    title: {
      required: "Tytuł jest wymagany.",
    },
    message: {
      required: "Treść wiadomości jest wymagana.",
    },
    commission_option: {
      required: "Opcja wynagodzenia jest wymagana.",
      selected_invalid: "Opcja wynagrodzenia musi być jedną z opcji powyżej.",
    },
    is_verify: "Konto z podanym numerem telefonu już istnieje.",
    name: {
      required: "Nazwa jest wymagana.",
    },
    start_date: {
      invalid_date: "Data jest nieprawidłowa",
    },
    end_date: {
      invalid_date: "Data jest nieprawidłowa",
    },
    date: {
      at_least_characters: "Nie można wpisać starszego roku niż 2022",
    },
  },

  error_page: {
    ups: "Ups, coś poszło nie tak. Poczekaj chwilę lub spróbuj odświeżyć stronę.",
    refresh: "Odśwież",
  },

  examinations: {
    package: "pakiet",
    no_examinations: "Brak badań.",
    no_packages: "Brak pakietów.",
  },

  search: {
    examinations: "szukane badania",
    packages: "szukane pakiety",
  },

  filters: {
    type: "Typ",
    status: "Status",
    referral_number: "Numer zlecenia",
    patient: "Pacjent",
    doctor: "Lekarz",
    price: "Cena",
    commission: "Wynagrodzenie",
    date_of_issue: "Data wystawienia",
    cost: "Koszt badania",
    company_name: "Nazwa przychodni",
    patient_discount_percent: "Procent rabatu",
  },

  patient_discount_percent: {
    zero: "0 %",
    five: "5 %",
    ten: "10 %",
    fifteen: "15 %",
    all: "Wszystkie",
  },

  homepage: {
    doctor: "Lekarz",
    patient: "Pacjent",
    admin: "Administrator",
    cms: "CMS",
    shop: "Sklep",
    faq: "FAQ",
    about_us: "O nas",
    contact: "Kontakt",
    catalog_of_examinations: "Katalog badań",
    cart: "Koszyk",
    my_referrals: "Moje zlecenia",
    discount_1: "Do",
    discount_2: "Na wszystkie pakiety badań",
    discount_3: "Sprawdź",
    highest_quality_research:
      "Wykonaj najwyższej jakości badania w całej Polsce",
    choose_from:
      "Wybierz spośród ponad 3500 badań, 68 kategorii badań i pakietów.",
    join_special_actions:
      "Dołącz do akcji specjalnych, korzystaj z promocji, twórz pakiety własne oraz używaj konta pacjenta z historią i analizami wyników badań.",
    shortcuts: "Na skróty",
    morphology: "Morfologia",
    glucose: "Glukoza",
    cpr: "CRP",
    creatynine: "Kreatynina",
    potassium: "Potas",
    iron: "Żelazo",
    mercury_in_the_blood: "Rtęć we krwi",
    collection_points_1: "Ponad",
    collection_points_2: "Punktów Pobrań",
    research_every_year_1: "Ponad",
    research_every_year_2: "mln",
    research_every_year_3: "badań rocznie",
    years_on_the_market_1: "Ponad",
    years_on_the_market_2: "lat",
    years_on_the_market_3: "na rynku",
    what_examination_are_you_looking_for: "Jakiego badania szukasz?",
  },

  layouts: {
    issue_referral: "Wystaw zlecenie",
    referral_history: "Historia zleceń",
    patient_list: "Lista pacjentów",
    main_page: "Strona główna",
    examintons_list: "Katalog badań",
    own_packages: "Pakiety własne",
  },

  medical_categories: {
    allergist: "Alergolog",
    pediatric_allergist: "Alergolog dziecięcy",
    andrologist: "Androlog",
    anesthesiologist: "Anestezjolog",
    angio_surgeon: "Angiochirurg",
    angiologist: "Angiolog",
    audiologist: "Audiolog",
    bariatra: "Bariatra",
    forensic_expert: "Biegły Sądowy",
    surgeon: "Chirurg",
    pediatric_surgeon: "Chirurg dziecięcy",
    thoracic_surgeon: "Chirurg klatki piersiowej",
    vascular_surgeon: "Chirurg naczyniowy",
    plastic_surgeon: "Chirurg plastyczny",
    dental_surgeon: "Chirurg stomatologiczny",
    maxillofacial_surgeon: "Chirurg Szczękowo-Twarzowy",
    dermatologist: "Dermatolog",
    pediatric_dermatologist: "Dermatolog Dziecięcy",
    diabetologist: "Diabetolog",
    pediatric_diabetologist: "Diabetolog Dziecięcy",
    diagnostic: "Diagnostyk",
    dietician: "Dietetyk",
    endocrinologist: "Endokrynolog",
    pediatric_endocrinologist: "Endokrynolog Dziecięcy",
    physiatrist: "Fizjoterapeuta",
    pediatric_physiatrist: "Fizjoterapeuta Dziecięcy",
    phlebologist: "Flebolog",
    gastrologist: "Gastrolog",
    pediatric_gastrologist: "Gastrolog Dziecięcy",
    geneticist: "Genetyk",
    geriatrician: "Geriatra",
    gynecologist: "Ginekolog",
    pediatric_gynecologist: "Ginekolog dziecięcy",
    hematologist: "Hematolog",
    pediatric_hematologist: "Hematolog dziecięcy",
    hepatologist: "Hepatolog",
    dental_hygienist: "Higienistka stomatologiczna",
    hypertensiologist: "Hipertensjolog",
    histopathologist: "Histopatolog",
    immunologist: "Immunolog",
    internist: "Internista",
    cardiac_surgeon: "Kardiochirurg",
    cardiologist: "Kardiolog",
    pediatric_cardiologist: "Kardiolog dziecięcy",
    laryngologist: "Laryngolog",
    pediatric_laryngologist: "Laryngolog dziecięcy",
    infectious_diseases_physician: "Lekarz Chorób Zakaźnych",
    occupational_medicine_physician: "Lekarz Medycyny Pracy",
    doctor_of_emergency_medicine: "Lekarz Medycyny Ratunkowej",
    physician_of_medical_rehabilitation: "Lekarz Rehabilitacji Medycznej",
    family_doctor: "Lekarz rodzinny",
    sports_doctor: "Lekarz sportowy",
    sports_medicine_physician: "Lekarz medycyny sportowej",
    a_doctor_in_the_process_of_specialization: "Lekarz w trakcie specjalizacji",
    doctor_without_specialization: "Lekarz bez specjalizacji",
    logopedist: "Logopeda",
    nephrologist: "Nefrolog",
    pediatric_nephrologist: "Nefrolog dziecięcy",
    neonatologist: "Neonatolog",
    neurosurgeon: "Neurochirurg",
    pediatric_neurosurgeon: "Neurochirurg dziecięcy",
    neurologist: "Neurolog",
    pediatric_neurologist: "Neurolog dziecięcy",
    ophthalmologist: "Okulista",
    pediatric_ophthalmologist: "Okulista dziecięcy",
    oncologist: "Onkolog",
    pediatric_oncologist: "Onkolog dziecięcy",
    optometrist: "Optometrysta",
    orthodontist: "Ortodonta",
    orthopedist: "Ortopeda",
    pediatric_orthopedist: "Ortopeda dziecięcy",
    orthoptist: "Ortoptysta",
    osteopath: "Osteopata",
    pathomorphologist: "Patomorfolog",
    pediatrician: "Pediatra",
    perinatologist: "Perinatolog",
    nurse: "Pielęgniarka",
    obstetrician: "Położnik",
    midwife: "Położna",
    proctologist: "Proktolog",
    prosthetist: "Protetyk",
    psychiatrist: "Psychiatra",
    child_psychiatrist: "Psychiatra dziecięcy",
    psychologist: "Psycholog",
    child_psychologist: "Psycholog dziecięcy",
    psycho_oncologist: "Psychoonkolog",
    psychotherapist: "Psychoterapeuta",
    psychotraumatologist: "Psychotraumatolog",
    pulmonologist: "Pulmonolog",
    pediatric_pulmonologist: "Pulmonolog dziecięcy",
    radiologist: "Radiolog",
    radiation_therapist: "Radioterapeuta",
    rheumatologist: "Reumatolog",
    pediatric_rheumatologist: "Reumatolog dziecięcy",
    sexologist: "Seksuolog",
    specialist_in_aesthetic_medicine: "Specjalista Medycyny Estetycznej",
    dentist: "Stomatolog",
    pediatric_dentist: "Stomatolog dziecięcy",
    transplantologist: "Transplantolog",
    coach: "Trener",
    ultrasonograph: "Ultrasonograf",
    urologist: "Urolog",
    pediatric_urologist: "Urolog dziecięcy",
    venereologist: "Wenerolog",
    main_category: "Specjalizacja",
    list: "Specjalizacje",
    all: "Wszystkie",
  },

  messages: {
    code: {
      resend: "Kod został wysłany ponownie.",
      resend_error: "Wystąpił błąd podczas wysyłania kodu.",
    },
    edit: {
      success: "Zmiany zostały zapisane.",
      failure: "Wystąpił błąd. Zmiany nie zostały zapisane.",
    },
    edit_amd_send_seferral: {
      success: "Zmiany zostały zapisane oraz ponownie wysłano zlecenie.",
    },
    save: {
      success: "Zapisano pomyślnie.",
      failure: "Wystąpił błąd. Nie udało się zapisać.",
    },
    sms: {
      success: "Sms został wysłany ponownie.",
      failure: "Wystąpił błąd. Sms nie został wysłany ponownie.",
    },
    send: {
      failure: "Wystąpił błąd. Nie udało się wysłać wiadomości.",
    },
    payment_token: {
      failure:
        "Wystąpił błąd. Spróbuj ponownie albo skontaktuj się z administratorem.",
    },
    permission: {
      failure: "Nie można wykonać.",
      forbidden:
        "Konto jest nieaktywne, skontaktuj się z biurem obsługi klienta.",
    },
    download: {
      failure: "Wystąpił błąd podczas pobierania pliku.",
      no_invoice: "Brak faktur z wybranego miesiąca.",
      no_files: "Nie znaleziono plików do pobrania.",
    },
  },

  not_found_page: {
    error: "Błąd",
    not_found: "Nie znaleziono strony",
    go_back: "Wróć na stronę główną",
  },

  pages: {
    account: "Ustawienia konta",
    history_orders: "Historia zleceń",
    my_settlements: "Moje rozliczenia",
    doctors: "Lekarze",
    referrals: "Zlecenia",
    invoices: "Faktury",
    your_referrals: "Twoje zlecenia",
    clientinvoices: "Faktury klientów",
    raports: "Raporty",
  },

  patients: {
    edit_patient: "Edytuj wybranego pacjenta",
    edit: "Edytuj pacjenta",
    no_patients: "Nie posiadasz jeszcze pacjentów.",
    patient_in_system_assign_to_another_doc:
      "Pacjent znajduje się już w naszym systemie",
    request_access_to_historical_test_results:
      "W celu przeprowadzenia lepszej diagnostyki poproś o dostęp do historycznych wyników badań",
  },

  referral_status: {
    doctor: {
      issued: "Wystawione",
      paid: "Opłacone",
      canceled: "Anulowane",
      completed: "Zrealizowane",
      all: "Wszystkie",
    },
    admin: {
      issued: "Wystawione",
      paid: "Opłacone",
      canceled: "Anulowane",
      completed: "Zrealizowane",
      all: "Wszystkie",
      visit_data_missing: "Zamówienie nie posiada wybranej daty wizyty",
    },
    patient: {
      issued: "Nowe",
      paid: "Opłacone",
      canceled: "Anulowane",
      completed: "Zrealizowane",
    },
  },

  own_packages: {
    examination_details: "Szczegóły badania",
    package_details: "Szczegóły pakietu",
    add_to_own_package: "Dodaj do pakietu własnego",
    create_first_own_package:
      "Utwórz swój pierwszy pakiet własny wpisując poniżej jego nazwę.",
    edit_own_package: "Edycja nazwy pakietu własnego",
    your_own_packages: "Twoje pakiety własne",
    add_to_new_own_package: "Dodaj do nowego pakietu własnego",
    create_own_package: "Stwórz pakiet własny",
    create_own_examination_package: "Stwórz własny pakiet badań",
    other_examinations: "Pozostałe badania",
    examination_packages: "Pakiety badań",
    own_packages: "Pakiety własne",
    create_first_own_examination_package: "Stwórz swój pierwszy pakiet badań",
    thanks_to_own_packages: "Dzięki pakietom własnym",
    faster_access:
      "Będziesz miał szybszy dostęp do badań których często szukasz",
    shorter_working_time: "Skrócisz czas swojej pracy i wystawiania zleceń",
    edit_package_name: "Edytuj nazwę pakietu",
    delete_package: "Usuń pakiet",
    add_to_cart: "Dodaj do koszyka",
    add_to_cart_selected: "Dodaj wybrane badania",
    add_new_own_package: "Dodaj nowy pakiet własny",
    update_own_package: "Edytuj pakiet własny",
    name_own_package: "Nazwa pakietu własnego",
    delete_new_own_package: "Usuń pakiet własny",
    delete_new_own_package_confirm: "Czy na pewno chcesz usunąć pakiet",
    examinations: "badania",
    examinations_counter: "badań",
    add_examinations_to_package: "Dodaj kolejne badanie do pakietu",
    no_examinations_in_package_1:
      "Nie dodałeś jeszcze żadnych badań do swojego pakietu",
    no_examinations_in_package_2: "Jak dodać badania do pakietu własnego?",
    no_examinations_in_package_3:
      "Przy każdym badaniu (pod jego nazwą), znajduje się niebieska gwiazdka z linkiem, która umożliwia dodania wybranego badania do pakietu własnego",
    no_examinations_in_package_4:
      "Kliknij w nią i wybierz do którego pakietu chcesz dodać badanie. Pamiętaj, że możesz stworzyć nieskończenie wiele pakietów, warto tylko nazywać je w zrozumiały i charakterystyczny dla Ciebie sposób",
    no_examinations_in_package_5: "Przejdź do listy z wyborem badań",
  },

  referrals: {
    receive_VAT_invoice: "Chcę otrzymać fakturę VAT",
    to_receive_an_invoice:
      "Aby otrzymać fakturę prosimy kontaktować się na adres email:",
    enter_study_name: "Wpisz nazwę badania",
    referral: "Zlecenie",
    number_short: "nr",
    examinations: "badania",
    date_of_issue: "data wystawienia",
    date_of_test: "data wykonania badań",
    exact_price: "Dokładną cenę poznasz po wyborze Punktu Pobrań",
    doctor: "lekarz",
    number: "Zlecenie nr",
    issued_on: "Wystawiono dnia",
    see_results: "Zobacz wyniki badań",
    commissioned_examinations: "Zlecone badania",
    patient: "Pacjent",
    phone_number: "Nr telefonu",
    pesel: "Pesel",
    payment: "Płatność",
    commission: "Twoje wynagrodzenie",
    add_or_choose_patient:
      "Uzupełnij dane nowego pacjenta lub znajdź na liście swoich pacjentów",
    issue_referrals: "Wystaw zlecenie",
    patient_list: "Lista pacjentów",
    my_patient_list: "Lista moich pacjentów",
    choose_patient: "Wybierz pacjenta z listy",
    full_name: "Imię i nazwisko",
    search_patient: "Wpisz imię, nazwisko, pesel lub nr telefonu",
    search_referral_patient: "Wpisz imię lub nazwisko",
    send_sms_again: "Wyślij sms ponownie",
    edit_issued_referral: "Edytuj zlecone badania",
    issued_referral_text_part1:
      "Wystawiłeś zlecenie. Wysłaliśmy SMS do pacjenta z instrukcją realizacji zlecenia.",
    issued_referral_text_part2:
      "Otrzymasz wynagrodzenie, kiedy pacjent opłaci zlecenie.",
    referral_for_examination: "Zlecenie badań laboratoryjnych",
    referral_for_examination_text:
      "Wydrukowane zlecenie Twój pacjent może zrealizować bezpośrednio w Punkcie Pobrań ALAB laboratoria. Brak możliwości płatności w punkcie. Płatność tylko ONLINE.",
    print_referral: "Drukuj zlecenie",
    collection_facilities: "Punkty Pobrań",
    collection_facilities_text:
      "Sprawdź, w którym Punkcie Pobrań Twój pacjent może zrealizować zlecenie",
    see_collection_facilities: "Zobacz Punkty Pobrań",
    examination_findings: "Wyniki badań",
    examination_findings_text:
      "Wyniki badań będą dostępne po zrealizowaniu zlecenia",
    unavailable: "Niedostępne",
    waiting_for_payment: "Oczekiwanie na płatność",
    waiting_for_commissions: "Oczekiwanie na wynagrodzenie",
    price_online_payment:
      "Cena przy płatności internetowej w serwisie ALAB Online.pl",
    price_facility_payment: "Cena przy płatności w Punkcie Pobrań Alab",
    commission_after_purchase:
      "Wynagrodzenie naliczy się po wykupieniu zlecenia przez pacjenta",
    no_referrals: "Nie posiadasz wystawionych zleceń.",
    issue_referral: "Wystaw zlecenie na badanie",
    popular_examinations: "Najczęściej wybierane badania",
    package: "pakiet",
    added: "Dodano",
    number_of_referrals: "Ilość zleceń",
    average_number_of_referrals: "Śr. ilość zleceń",
    average_price_of_referral: "Śr. cena zlecenia",
    top_doctors: "Lekarze, którzy wystawili zlecenia na największą sumę:",
    your_facility: "Twój Punkt Pobrań",
    choose_examinations: "Dobierz badania",
    additionally_examinations:
      "Jeżeli chcesz, możesz dodatkowo wykonać inne badania",
    ordered_examinations: "Zlecone badania",
    change: "Zmień dane i wyślij zlecenie ponownie",

    tabs: {
      packages: "Pakiety",
      examinations: "Badania",
    },

    table: {
      id: "Id zlecenia",
      date: "Data",
      full_name: "Imię i nazwisko",
      identity_card: "Nr dowodu",
      status: "Status",
      phone: "Telefon",
      number_of_examinations: "Ilość badań",
      commission: "Wynagrodzenie",
      show: "Zobacz zlecenie",
      show_patient: "Zobacz pacjenta",
      code: "Kod pocztowy",
      city: "Miasto",
      street: "Ulica",
      cost: "Koszt badania",
      pesel: "Pesel",
      patient_discount_percent: "Procent rabatu",
      patient_discount: "Rabat",
    },
  },

  results: {
    download_pdf: "Pobierz wyniki w PDF",
    back_to_referral: "Powrót do zlecenia",
    choose_examination: "Wybierz badanie, żeby wyświetlić wyniki",
  },

  select_time: {
    current_month: "Bieżący miesiąc",
    previous_month: "Poprzedni miesiąc",
    last_90_days: "Ostatnie 90 dni",
    last_6_months: "Ostatnie 6 miesięcy",
    current_year: "Bieżący rok",
    selected_from: "Wybierz z kalendarza",
  },

  titles: {
    your_finances: "Twoje finanse",
    your_stats: "Twoje statystyki",
    stats: "Statystyki",
    top_doctors: "Top lekarze",
  },

  your_finances: {
    to_pay: "Do wypłaty",
  },

  your_referrals: {
    see_results: "Zobacz wyniki badań",
    pay: "Opłać badania",
    see_summary: "Zobacz podsumowanie",
    just_paid: "Opłaciłeś już to zlecenie.",
    go_to_facility: "Udaj się do Punktu Pobrań, żeby wykonać badania.",
  },
  documents: {
    terms: "Regulamin serwisu",
    privacy_policy: "Polityka prywatności",
  },

  your_settlements: {
    user_full_name: "Imię i nazwisko",
    address: "Adres",
    billing_data: "Dane do faktury",
    edit_billing_data: "Edytuj dane do faktury",
    my_settlements: "Moje rozliczenia",
    verify_data_title: "Zweryfikuj swoje dane",
    verify_data_text:
      "Podaj dane do rozliczeń, aby móc rozliczyć wynagrodzenie od wystawionych zleceń",
    company_data_title: "Dane firmy",
    company_name: "Nazwa firmy / przychodni",
    nip: "NIP",
    free_from_vat_title: "Czy jesteś zwolniony z VAT?",
    free_from_vat:
      "Oświadczam, iż przysługuje mi prawo do zwolnienia podmiotowego przewidzianego w art. 113 ust. 1 ustawy o VAT",
    address_title: "Adres",
    street: "Ulica",
    house_number: "Numer budynku",
    flat_number: "Numer lokalu",
    postal_code: "Kod pocztowy",
    city: "Miejscowość",
    account_number_title: "Numer rachunku bankowego",
    account_number: "Numer rachunku bankowego",
    verify_account: "Zweryfikuj konto",
    your_invoices: "Twoje faktury",

    checkboxes: {
      check_all: "Zaznacz wszystkie",
      agree_terms: "Oświadczam, iż przeczytałem i akceptuję",
      terms: "regulamin",
      marketing:
        "Wyrażam zgodę na przesyłanie informacji handlowych i marketingowych, za pośrednictwem środków komunikacji elektronicznej.",
      agree3:
        "Donec dolor mi, interdum vel arcu vel, gravida sollicitudin est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed accumsan dui et sollicitudin porttitor",
      agree4:
        "Donec sed lacus leo. Fusce fermentum sem sed odio luctus, eu sollicitudin massa elementum. Maecenas pellentesque ligula libero, vel condimentum sem lobortis et",
    },

    invoice: {
      date: "Data",
      number: "Numer faktury",
      amount: "Kwota",
      download: "Pobierz fakture",
    },
  },
  share: {
    patient_examinations: "Badania pacjenta",
    approvals: "Zgody",
    ask_for_access: "Poproś o dostęp do wyników badań",
  },

  your_stats: {
    issued_referrals: "Wystawione zlecenia",
    paid_referrals: "Opłacone zlecenia",
    completed_referrals: "Zrealizowane zlecenia",
    canceled_referrals: "Anulowane zlecenia",
    commission: "Wynagrodzenie",
    cost: "Koszt badań",
  },
  payment: {
    made: "Platność zrealizowana.",
    canceled: "Płatność anulowana.",
    advance: "Oczekiwanie na potwierdzenie płatności.",
    returned: "Płatność zwrócona.",
    go_to_dashboard: "Przejdź do panelu głównego",
    retry_payment: "Ponów płatność",
  },
  category: {
    bundles_categories: "kategorie pakietów",
    examinations_categories: "kategorie badań",
  },

  feature: {
    patient_own_packages: "Pakiety własne pacjenta",
    doctor_own_packages: "Pakiety własne lekarza",
    cart_pay_for_doctor_referral: "Koszyk opłać zlecenie od lekarza",
    invoice_data: "Dane do faktury",
    collection_points_filters: "Filtry dla Punktów Pobrań",
  },

  collection_points: {
    filters: {
      is_covid_private: "Przystosowany do COVID19",
      is_children: "Przyjazny dzieciom",
      is_40_plus: "Profilaktyka 40+",
      is_ginecology: "Ginekologiczny",
      is_sport: "ALAB Sport",
      is_swab: "Pobór wymazów",
      is_parking: "Przy laboratorium",
      open_sunday: "Otwarty w niedzielę",
      open_saturday: "Otwarty w sobotę",
    },
    additional_info: "Dodatkowe informacje",
    additional_open: "Dodatkowe godziny otwarcia",
    additional_close: "Punkt NIECZYNNY",
  },
};
