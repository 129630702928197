export default {
  namespaced: true,
  state: {
    crumbs: [],
  },
  getters: {
    getCrumbs: (state) => state.crumbs,
    getFilledCrumbs: (state) =>
      state.crumbs.filter((crumb) => crumb?.name !== undefined),
    getKeyCrumbs: (state) =>
      state.crumbs.filter((crumb) => crumb?.key !== undefined),
  },
  mutations: {
    setCrumbs(store, crumbs) {
      store.crumbs = crumbs;
    },
    updateCrumbsParams(store, params = []) {
      params.forEach(({ key, value }) => {
        store.crumbs = store.crumbs.map((crumb) => {
          return { ...crumb, name: crumb.name.replace(key, value) };
        });
      });
    },
  },
};
