import http from "@/utils/axios";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    results: [],
  },
  getters: {
    getResults: (state) => state.results,
  },
  mutations: {
    setResults(state, results) {
      state.results = results;
    },
    clearResults(state) {
      state.results = [];
    },
  },
  actions: {
    async fetchResults({ commit }, id) {
      await http
        .get(`referrals/results/${id}`)
        .then(({ data }) => {
          commit("setResults", data.results);
        })
        .catch(({ response }) => {
          if (response && response.status === 403)
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
        });
    },
    downloadResults(_, id) {
      http({
        url: `/referrals/download-result/${id}`,
        method: "GET",
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          const type = response.headers["content-type"];
          let filename =
            response.headers["content-disposition"].split("filename=")[1];
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: type })
          );
          let link = document.createElement("a");
          link.href = url;
          link.download = filename ?? "results.zip";
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            URL.revokeObjectURL(url);
            link.remove();
          }, 1000);
        })
        .catch(({ response }) => {
          if (response && response.status === 403) {
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
            return;
          } else if (response && response.status === 404) {
            notify({
              type: "danger",
              text: "messages.download.no_files",
              duration: 5000,
            });
          } else {
            notify({
              type: "danger",
              text: "messages.download.failure",
              duration: 5000,
            });
          }
        });
    },
  },
};
