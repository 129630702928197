import http from "@/utils/axios";
import { notify } from "@kyvg/vue3-notification";
import router from "../../router";

export default {
  namespaced: true,
  state: {
    checkAll: false,
    billingData: {
      company_name: "",
      nip: "",
      free_from_vat: false,
      street: "",
      house_number: "",
      flat_number: "",
      city: "",
      account_number: "",
      agree1: false,
      agree2: false,
    },
    errors: [],
  },
  getters: {
    getBillingData: (state) => state.billingData,
    getErrors: (state) => state.errors,
    getCheckAll: (state) => state.checkAll,
  },
  mutations: {
    setBillingData(state, billingData) {
      Object.assign(state.billingData, billingData);
    },
    setBillingDataValue(state, billingData) {
      Object.assign(state.billingData, billingData);
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    setCheckAll(state, checkAll) {
      state.checkAll = checkAll;
      state.billingData.agree1 = checkAll;
      state.billingData.agree2 = checkAll;
    },
    clearBillingData(state) {
      Object.keys(state.billingData).forEach(
        (key) => (state.billingData[key] = "")
      );
    },
    clearErrors(state) {
      state.errors = [];
    },
  },
  actions: {
    async submitBillingForm({ commit, state, rootGetters }, redirect) {
      const hasBillingData = rootGetters["user/getCurrentUserBillingData"]
        ? true
        : false;

      const bilingData = {
        ...state.billingData,
        account_number: state.billingData.account_number.replace("PL", ""),
      };

      await http
        .post(
          `/doctor/billing-data/${hasBillingData ? `update` : `store`}`,
          bilingData
        )
        .then(({ data }) => {
          commit("user/setUserBillingData", data.billing_data, {
            root: true,
          });
          commit("clearErrors");
          notify({
            type: "success",
            text: hasBillingData
              ? "messages.edit.success"
              : "messages.save.success",
            duration: 5000,
          });
          if (hasBillingData)
            commit("modals/closeModal", "changeBillingData", { root: true });
          if (redirect) router.push({ name: "Dashboard" });
        })
        .catch(({ response }) => {
          if (response && response.status === 422)
            commit("setErrors", response.data.errors);
          notify({
            type: "danger",
            text: hasBillingData
              ? "messages.edit.failure"
              : "messages.save.failure",
            duration: 5000,
          });
        });
    },
  },
};
